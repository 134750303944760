import { RecursivePartial } from '../base';
import { BaseLayoutElement } from './_base-element';

export enum TaskFieldType {
    Unknown         = 0,
    ShortId         = 1,
    Subj            = 2,
    Creator         = 3,
    Assignee        = 4,
    Status          = 5,
    Created         = 6,
    Favorite        = 7,
    Tags            = 8,
    Priority        = 9,
    Duedate         = 10,
    Unread          = 11,
    Archived        = 12,
}

export class TaskFieldLayoutElement extends BaseLayoutElement {

    subType: TaskFieldType = TaskFieldType.Unknown;
    userMode?: 'icon' | 'text' | 'full';
    statusMode?: 'icon' | 'text' | 'full';
    noProjectColor?: boolean;
    absoluteDate?: boolean;
    size?: number;
    priority?: 'flag' | 'square';
    noTitle?: boolean;
    maxRows?: number;
    vzSize?: 'small' | 'default' | 'large';

    constructor(json?: RecursivePartial<TaskFieldLayoutElement>) {
        super(json);
        this.parseTaskFieldLayoutElement(json || {});
    }

    parse(json: RecursivePartial<TaskFieldLayoutElement>): TaskFieldLayoutElement {
        super.parse(json);
        this.parseTaskFieldLayoutElement(json);
        return this;
    }

    private parseTaskFieldLayoutElement(json: RecursivePartial<TaskFieldLayoutElement>): void {
        TaskFieldLayoutElement.assignFields<TaskFieldLayoutElement>(this, json, [
            'subType',
            'userMode',
            'statusMode',
            'noProjectColor',
            'absoluteDate',
            'size',
            'priority',
            'noTitle',
            'maxRows',
            'vzSize'
        ]);
    }

}
