import { LayoutElementType as LET, LayoutElement, TaskFieldType as TFT } from "@models/layout-tpl";
import { RecursivePartial } from "@models/base/helper-types";

export const ctClear: RecursivePartial<LayoutElement> = {
    type: LET.Box,
    direction: 'vert',
    flex: 1,
    borderColor: 'var(--vzt-priority-color)',
    borderStyle: 'solid',
    borderWidth: '0 0 0 2px',
    padding: '8px 8px 2px 8px',
    position: 'relative',
    minHeight: '145px',
    width: '100%',
    items: [
        {
            type: LET.TaskField,
            subType: TFT.Archived,
            position: 'absolute',
            bottom: '-10px',
            left: '10px',
            fontSize: '100px',
            fontWeight: 600,
            opacity: .05,
            color: 'var(--vz-textColor)',
        },
        {
            type: LET.Box,
            items: [
                {
                    type: LET.TaskField,
                    subType: TFT.ShortId,
                    margin: '0 4px',
                },
                {
                    type: LET.TaskField,
                    subType: TFT.Duedate,
                    padding: '0 4px',
                },
                {
                    type: LET.TaskField,
                    subType: TFT.Status,
                    padding: '0 4px',
                    margin: '-8px 0 0 0',
                },
                {
                    type: LET.Box,
                    flex: 1,
                },
                {
                    type: LET.TaskField,
                    subType: TFT.Priority,
                    priority: 'square',
                    margin: '0 4px',
                },
                {
                    type: LET.TaskField,
                    subType: TFT.Favorite,
                    margin: '-1px 0 0 0',
                },
            ]
        },
        {
            type: LET.Box,
            padding: '0 4px',
            flex: 1,
            items: [
                {
                    type: LET.TaskField,
                    subType: TFT.Subj,
                    maxRows: 2,
                },
            ]
        },
        {
            type: LET.Box,
            start: true,
            padding: '0 4px',
            wrap: true,
            endJustified: true,
            items: [
                {
                    type: LET.Box,
                    flex: 1,
                },
                {
                    type: LET.TaskField,
                    subType: TFT.Tags,
                },
            ]
        },
        {
            type: LET.Box,
            padding: '4px',
            items: [
                {
                    type: LET.Box,
                    padding: '4px',
                    center: true,
                    items: [
                        {
                            type: LET.TaskField,
                            subType: TFT.Unread,
                            fontSize: '20px',
                            margin: '4px 0',
                            boxShadow: '0 0 80px 25px',
                            borderRadius: '50%',
                        },
                    ],
                },
                {
                    type: LET.Box,
                    flex: 1,
                    endJustified: true,
                    center: true,
                    items: [
                        {
                            type: LET.TaskField,
                            subType: TFT.Creator,
                            margin: '4px 0',
                            color: 'var(--vz-textColorL)',
                            size: 20,
                            userMode: 'full',
                        },
                        {
                            type: LET.Icon,
                            fontSize: '16px',
                            padding: '5px 2px 3px 3px',
                            color: 'var(--vz-textColorL)',
                            name: 'arrow-right',
                        },
                        {
                            type: LET.TaskField,
                            subType: TFT.Assignee,
                            margin: '4px 6px 4px 0',
                            color: 'var(--vz-textColorL)',
                            size: 26,
                            userMode: 'full',
                        },
                    ]
                },
            ]
        },
    ]
};
