import { LayoutElementType as LET, LayoutElement, TaskFieldType as TFT } from "@models/layout-tpl";
import { RecursivePartial } from "@models/base/helper-types";

export const ctForSelect: RecursivePartial<LayoutElement> = {
    type: LET.Box,
    flex: 1,
    borderColor: 'var(--vz-borderColor)',
    borderStyle: 'solid',
    borderWidth: '1px 1px 1px 0',
    width: '100%',
    items: [
        {
            type: LET.Box,
            direction: 'vert',
            flex: 1,
            borderColor: 'var(--vzt-priority-color)',
            borderStyle: 'solid',
            borderWidth: '0 0 0 2px',
            position: 'relative',
            minHeight: '98px',
            maxHeight: '98px',
            padding: '4px 0',
            width: '100%',
            items: [
                {
                    type: LET.TaskField,
                    subType: TFT.Archived,
                    position: 'absolute',
                    bottom: '0',
                    left: '4px',
                    fontSize: '32px',
                    fontWeight: 600,
                    opacity: .05,
                    color: 'var(--vz-textColor)',
                },
                {
                    type: LET.Box,
                    items: [
                        {
                            type: LET.TaskField,
                            subType: TFT.Priority,
                            priority: 'square',
                            margin: '0 4px',
                        },
                        {
                            type: LET.TaskField,
                            subType: TFT.ShortId,
                            margin: '0 4px',
                        },
                        {
                            type: LET.TaskField,
                            subType: TFT.Favorite,
                            margin: '-1px 0px 0 0',
                        },
                        {
                            type: LET.TaskField,
                            subType: TFT.Duedate,
                            padding: '0 4px',
                            noTitle: true,
                        },
                        {
                            type: LET.TaskField,
                            subType: TFT.Status,
                            padding: '0 4px',
                            margin: '-6px 0 2px'
                        },
                        {
                            type: LET.Box,
                            flex: 1,
                        },
                        {
                            type: LET.Box,
                            items: [
                                {
                                    type: LET.TaskField,
                                    subType: TFT.Creator,
                                    color: 'var(--vz-textColorL)',
                                    size: 22,
                                    userMode: 'icon',
                                },
                                {
                                    type: LET.Icon,
                                    fontSize: '16px',
                                    padding: '2px 2px 1px 3px',
                                    color: 'var(--vz-textColorL)',
                                    name: 'arrow-right',
                                },
                                {
                                    type: LET.TaskField,
                                    subType: TFT.Assignee,
                                    margin: '0 6px 0 0',
                                    color: 'var(--vz-textColorL)',
                                    size: 22,
                                    userMode: 'icon',
                                },
                            ]
                        },
                    ]
                },
                {
                    type: LET.Box,
                    padding: '0 4px',
                    flex: 1,
                    items: [
                        {
                            type: LET.TaskField,
                            subType: TFT.Subj,
                            maxRows: 1,
                        },
                    ]
                },
                {
                    type: LET.Box,
                    start: true,
                    padding: '0 4px',
                    items: [
                        {
                            type: LET.Box,
                            flex: 1,
                        },
                        {
                            type: LET.TaskField,
                            subType: TFT.Tags,
                        },
                    ]
                },
            ]
        }
    ]
};
