<nz-drawer
    [nzClosable]="true"
    [nzVisible]="open"
    nzPlacement="right"
    [nzWidth]="mobile() ? '100%' : '550px'"
    [nzBodyStyle]="{ position: 'relative' }"
    nzTitle="Выберите значок"
    (nzOnClose)="close()">
    <ng-container *nzDrawerContent>
        <div class="select-color-drawer-root layout flex wrap scroll-y" [style.opacity]="op">
            <i *ngFor="let color of colors" class="scdr-color"
                [style.background-color]="color"
                [style.border-color]="color"
                (click)="close(color)">
            </i>
        </div>
    </ng-container>
</nz-drawer>
