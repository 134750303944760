// import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, input, signal, effect, inject, DestroyRef, booleanAttribute, numberAttribute } from '@angular/core';

import { getUuid } from '@models/utils/uuid';
import { string2Color } from '@root/_models';
import { DynCacheService } from '@services/dynamic-cache.service';

@Component({
    selector: 'vz-project-badge, [vz-project-badge]',
    template: `
        <span class="vz-project-badge"
            [style.background-color]="dispText() ? dispColor() : 'transparent'"
            [style.font-size.px]="size()"
            [title]="showTooltip() ? (tooltipPrefix() || '') + dispName() : ''">{{ dispText() }}</span>
    `,
    styles: `
        @import 'theme';
        :host {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .vz-project-badge {
                padding: 2px 3px;
                border-radius: 4px;
                color: @textColorInv;
            }
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ProjectBadgeComponent {
    guid          = input.required<string | undefined | null>();
    text          = input<string | undefined | null>();
    size          = input(10, { transform: numberAttribute });
    tooltipPrefix = input<string | undefined | null>();
    showTooltip   = input(true, { transform: booleanAttribute });

    dispColor   = signal<string>('#888');
    dispText    = signal<string>('');
    dispName    = signal<string>('');

    private _dc = inject(DynCacheService);
    private _destroy: DestroyRef = inject(DestroyRef);

    constructor() {
        const sid = getUuid();
        effect(() => {
            const pid = this.guid();
            const text = this.text();
            this._dc?.unWatchAll(sid);
            if (pid && text) {
                this.dispColor.set(string2Color(pid));
                this.dispText.set(text);
                this.dispName.set('');
            }
            else if (pid) {
                this._dc?.watchItem('project', pid, sid).subscribe(v => {
                    if (v?.item) {
                        this.dispColor.set(v.item._color);
                        this.dispName.set(v.item.name);
                        this.dispText.set(v.item.abbr);
                    }
                });
            }
        });
        this._destroy.onDestroy(() => this._dc?.unWatchItem('project', this.guid(), sid));
    }

}