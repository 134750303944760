import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

import { Subscription } from 'rxjs';

import { Task } from '@models';
import { isAfter, isPast, isToday } from 'date-fns';
import { DynCacheService } from '@services/dynamic-cache.service';

@Directive({ selector: '[vz-task-text-classes]' })
export class TaskTextClassesDirective {

    @Input('vz-task-text-classes')
    set task(v: Task | undefined) {
        this.applyClasses(v);
    }

    dueToday: boolean = false;
    dueExpired: boolean = false;
    subscription?: Subscription;

    constructor(
        private _el: ElementRef,
        private _r2: Renderer2,
        private _dc: DynCacheService
    ) { }

    applyClasses(t?: Task): void {
        if (!t) {
            return;
        }
        const el = this._el.nativeElement;
        this._r2.removeClass(el, 'fw-b');
        this._r2.removeClass(el, 'vz-task-archived');
        this._r2.removeClass(el, 'txt-warn');
        this._r2.removeClass(el, 'txt-error');


        if (t.workflowId && t.dueDate) {
            if (this.subscription && !this.subscription.closed) {
                this.subscription.unsubscribe();
                this.subscription = undefined;
            }
            this.subscription = this._dc.getItem('workflow', t.workflowId).subscribe({
                next: wf => {
                    const finished = wf?.finishStateId == t.stateId;
                    this.dueExpired = false;
                    this.dueToday = false;
                    if (t && t.dueDate) {
                        if ((t.archived || finished) && t.stateStartTime) {
                            this.dueExpired = t.dueDate && isPast(t.dueDate) && !isToday(t.dueDate) && isAfter(t.stateStartTime, t.dueDate);
                        }
                        else {
                            this.dueExpired = !!t.dueDate && isPast(t.dueDate);
                            this.dueToday = !!t.dueDate && isToday(t.dueDate);
                        }
                    }
                    if (this.dueExpired && !this.dueToday) {
                        this._r2.addClass(el, 'txt-error');
                    }
                    if (this.dueToday && !finished) {
                        this._r2.addClass(el, 'txt-warn');
                    }
                },
                error: () => {}
            });
        }
        else if (this.subscription && !this.subscription.closed) {
            this.subscription.unsubscribe();
            this.subscription = undefined;
        }

        if (!t.flags.seen) {
            this._r2.addClass(el, 'fw-b');
        }
        if (t.archived) {
            this._r2.addClass(el, 'vz-task-archived');
        }
    }

}
