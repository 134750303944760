import { ChangeDetectionStrategy, Component, effect, input, signal, untracked } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NzTypographyModule } from 'ng-zorro-antd/typography';

import { TaskFavoriteModule } from '@shared/_tasks/task-favorite.module';
import { TaskIdBadgeComponent } from '@shared/_tasks/task-id-badge.module';
import { TaskTextClassesDirective } from '@shared/_tasks/task-text-classes.directive';
import { AvatarModule } from '@shared/_views/avatar';
import { VzDtModule } from '@shared/_views/dt.module';
import { TagComponent } from '@shared/_views/tag';
import { DuedateTextClassesDirective } from '@shared/_tasks/duedate-text-classes.directive';

import { BaseComponent } from '@base/base.component';
import { Tag, Task, LayoutElement, LayoutElementType, TaskFieldType, TaskPriorityName, Workflow } from '@models';
import { StoreService } from '@services/store.service';
import { DynCacheService } from '@services/dynamic-cache.service';

@Component({
    selector: 'vz-layout-tpl, [vz-layout-tpl]',
    templateUrl: './layout-tpl.component.html',
    styleUrls: ['./layout-tpl.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        NzTypographyModule,
        TaskIdBadgeComponent, AvatarModule, VzDtModule, TagComponent, DuedateTextClassesDirective, TaskTextClassesDirective,
        TaskFavoriteModule
    ]
})
@Tag('LayoutTplComponent')
export class LayoutTplComponent extends BaseComponent {

    rootElement     = input<LayoutElement>();
    templateData    = input<{ task?: Task, options?: any }>();

    workflow        = signal<Workflow | undefined>(undefined);

    LET = LayoutElementType;
    TFT = TaskFieldType;
    TPN = TaskPriorityName;

    constructor(
        protected _store: StoreService,
        private _dc: DynCacheService
    ) {
        super(_store);
        effect(() => {
            const td = this.templateData();
            // this._L('constructor', 'templateData:', td);
            const wf = untracked(this.workflow);
            if (td?.task?.workflowId && (!wf?.id || wf?.id != td.task.workflowId)) {
                this._dc.unWatchAll(this.CID);
                this.workflow.set(undefined);
                this._dc.watchItem('workflow', td.task.workflowId, this.CID).subscribe(st => this.workflow.set(st?.item));
            }
        });
    }

}
