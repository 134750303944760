@if (sTag) {
    <div class="vz-tag layout center" [ngClass]="'vz-tag-' + tagSize" [title]="(pTag ? pTag._label + '\n' : '') + (sTag._label)">
        @if (pTag) {
            <div class="vzt-group layout center" [style.background-color]="pTag.color">
                @if (pTag.icon) {
                    <i class="vzt-icon" [ngClass]="pTag.icon"></i>
                }
                <span class="elli-wrap">
                    <span class="vzt-text elli">{{ pTag.text }}</span>
                </span>
            </div>
            <div class="vzt-separator" [style.border-left-color]="pTag.color"></div>
            <div class="vzt-separator2" [style.border-top-color]="sTag.color" [style.border-bottom-color]="sTag.color"></div>
        }
        <div class="vzt-tag layout center" [class.vzt-tag-nogroup]="!pTag" [style.background-color]="sTag.color">
            @if (sTag.icon) {
                <i class="vzt-icon" [ngClass]="sTag.icon"></i>
            }
            <span class="elli-wrap">
                <span class="vzt-text elli">{{ sTag.text }}</span>
            </span>
        </div>
    </div>
}
@else if (sTag === null) {
    <div class="vz-tag layout center" [ngClass]="'vz-tag-' + tagSize" [title]="'Тег не найден, #' + this.tagId">
        <div class="vzt-tag vzt-tag-nogroup" style="background-color: #ccc">
            <span class="vzt-text">#{{ tagId ? tagId.substring(0, 7) + '…' : '?' }}</span>
        </div>
    </div>
}
