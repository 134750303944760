<table *ngIf="filter && values" style="width: 100%" (keypress.control.enter)="enterPress.emit()">
    <ng-container *ngFor="let f of filter.fields; let ff = first; let l = last">

        <!-- <tr *ngIf="f && !f.hidden && mobile()"><th class="txt-l no-wrap">{{ f.name }}:</th></tr> -->
        <tr *ngIf="f && !f.hidden"
            nz-tooltip
            [nzTooltipTitle]="filterHelpTpl"
            nzTooltipOverlayClassName="vz-tooltip-light"
            [nzTooltipPlacement]="['right', 'top']"
            [nzTooltipTrigger]="mobile() ? null : 'hover'">
            <td *ngIf="!mobile()" class="txt-l no-wrap" width="1px">{{ f.name }}</td>

            <td [ngSwitch]="f.type" class="layout" [style.margin-bottom.px]="mobile() ?  8 : 0">
                <div class="layout flex" [ngClass]="mobile() ? 'vertical' : 'end'">
                    <b *ngIf="mobile()">{{ f.name }}:</b>
                    <ng-container *ngSwitchCase="FFT.String" [ngTemplateOutlet]="tplString" [ngTemplateOutletContext]="{ $implicit: f, ff }"></ng-container>
                    <ng-container *ngSwitchCase="FFT.Strings" [ngTemplateOutlet]="tplString" [ngTemplateOutletContext]="{ $implicit: f, ff }"></ng-container>
                    <ng-container *ngSwitchCase="FFT.Date" [ngTemplateOutlet]="tplDate" [ngTemplateOutletContext]="{ $implicit: f, ff }"></ng-container>
                    <ng-container *ngSwitchCase="FFT.DateOrUnset" [ngTemplateOutlet]="tplDate" [ngTemplateOutletContext]="{ $implicit: f, ff }"></ng-container>
                    <ng-container *ngSwitchCase="FFT.Users" [ngTemplateOutlet]="tplUsersOrGroups" [ngTemplateOutletContext]="{ $implicit: f, ff, wg: false }"></ng-container>
                    <ng-container *ngSwitchCase="FFT.UsersOrGroups" [ngTemplateOutlet]="tplUsersOrGroups" [ngTemplateOutletContext]="{ $implicit: f, ff, wg: true }"></ng-container>
                    <ng-container *ngSwitchCase="FFT.Projects" [ngTemplateOutlet]="tplProjects" [ngTemplateOutletContext]="{ $implicit: f, ff }"></ng-container>
                    <ng-container *ngSwitchCase="FFT.Orgs" [ngTemplateOutlet]="tplOrgs" [ngTemplateOutletContext]="{ $implicit: f, ff }"></ng-container>
                    <ng-container *ngSwitchCase="FFT.Enum">
                        <label *ngFor="let kv of f.typeData; let first = first"
                            [class.ml05]="first && mobile()"
                            nz-checkbox
                            [(ngModel)]="values![f.id][kv[0]]"
                            (ngModelChange)="updateValues()">
                            {{ kv[1] }}
                        </label>
                    </ng-container>
                    <ng-container *ngSwitchCase="FFT.Flag">
                        <label nz-checkbox [(ngModel)]="values![f.id]" (ngModelChange)="updateValues()"></label>
                        <!-- <nz-switch [(ngModel)]="values![f.id]" (ngModelChange)="updateValues()"></nz-switch> -->
                    </ng-container>
                    <ng-container *ngSwitchCase="FFT.Tags" [ngTemplateOutlet]="tplTags" [ngTemplateOutletContext]="{ $implicit: f, ff }"></ng-container>
                    <ng-container *ngSwitchDefault><span class="txt-err op05">неизвестный тип фильтра: {{f.type | json }}</span></ng-container>
                </div>
            </td>

        </tr>

        <ng-template #filterHelpTpl>
            <div class="layout vertical" style="width: 400px">
                <b class="txt">{{ f!.name }}</b>
                <i *ngIf="f?.desc" class="txt-l">{{ f!.desc }}</i>
            </div>
        </ng-template>

    </ng-container>
</table>

<ng-template let-f let-first="ff" #tplString>
    <input class="ph025 bc" style="border-bottom: 1px solid"
        [(ngModel)]="values![f.id]"
        (ngModelChange)="updateValues()"
        [aFocus]="first ? 100 : -1"
        nz-input
        [nzBorderless]="!mobile()"
        (keyup.enter)="enterPress.emit()">
</ng-template>

<ng-template let-f let-first="ff" #tplDate>
    <nz-select class="bc" style="border-bottom: 1px solid" [(ngModel)]="values![f.id].p" (ngModelChange)="updateValues()" [nzBorderless]="!mobile()">
        <nz-option nzLabel=">" nzValue=">"></nz-option>
        <nz-option nzLabel="<" nzValue="<"></nz-option>
        <nz-option nzLabel="=" nzValue="="></nz-option>
        <nz-option *ngIf="f.type == FFT.DateOrUnset" nzLabel="?" nzValue="?" (click)="values![f.id].dt = null"></nz-option>
    </nz-select>
    <nz-select class="bc" style="border-bottom: 1px solid; min-width: 110px" [(ngModel)]="values![f.id].tpl" (ngModelChange)="updateValues()" [nzBorderless]="!mobile()" nzAllowClear nzPlaceHolder="Шаблон">
        <nz-option nzLabel="Вчера" nzValue="yesterday"></nz-option>
        <nz-option nzLabel="Сегодня" nzValue="today"></nz-option>
        <nz-option nzLabel="Завтра" nzValue="tomorrow"></nz-option>
        <nz-option nzLabel="Эта неделя" nzValue="week"></nz-option>
        <nz-option nzLabel="Этот месяц" nzValue="month"></nz-option>
    </nz-select>
    <nz-date-picker
        class="flex"
        style="border-bottom: 1px solid var(--vz-borderColor) !important"
        [class.hidden]="values![f.id].p == '?' || !!values![f.id].tpl"
        [nzBorderless]="!mobile()"
        [(ngModel)]="values![f.id].dt"
        (ngModelChange)="updateValues()"
        [nzDisabled]="values![f.id].p == '?'">
    </nz-date-picker>
    <small *ngIf="values![f.id].p == '?'" class="self-center ml05 txt-p">Срок не установлен</small>
</ng-template>

<ng-template let-f let-first="ff" let-withGroups="wg" #tplUsersOrGroups>
    <person-or-group-select
        class="bc flex"
        style="border-bottom: 1px solid"
        [(ngModel)]="values![f.id]"
        (ngModelChange)="updateValues()"
        placeholder=""
        multiple="true"
        [persons]="persons"
        [groups]="withGroups ? groups : undefined"
        [borderless]="!mobile()" />
</ng-template>

<ng-template #tplSelectItem let-item>
    <vz-avatar
        class="ml-05"
        style="display: inline-block;"
        [style.margin-top.px]="1"
        [style.margin-right.px]="4"
        [uid]="item.nzValue.id"
        [size]="20"
        withName="true"
        [type]="isGroup[item.nzValue.id] ? 'group' : 'user'" />
</ng-template>

<ng-template let-f let-first="ff" #tplProjects>
    <vz-project-select
        class="bc flex"
        style="border-bottom: 1px solid"
        [(ngModel)]="values![f.id]"
        (ngModelChange)="updateValues()"
        [borderless]="!mobile()"
        placeholder=""
        multiple="true" />
</ng-template>

<ng-template let-f let-first="ff" #tplOrgs>
    <nz-select class="bc flex"
        style="border-bottom: 1px solid"
        [(ngModel)]="values![f.id]"
        (ngModelChange)="updateValues()"
        [nzBorderless]="!mobile()"
        nzMode="multiple"
        [nzMaxMultipleCount]="10"
        [compareWith]="compareById"
        nzShowSearch
        nzAllowClear>
        <nz-option *ngFor="let item of orgs" nzCustomContent [nzLabel]="item.name" [nzValue]="item">
            <vz-avatar style="display: inline-block;" [item]="item" size="20" withName="true" type="org"></vz-avatar>
        </nz-option>
    </nz-select>
</ng-template>

<ng-template let-f let-first="ff" #tplTags>
    <vz-tags-select
        class="bc flex"
        style="border-bottom: 1px solid"
        [(ngModel)]="values![f.id]"
        (ngModelChange)="updateValues()"
        [projectId]="projectId"
        [borderless]="!mobile()"
        placeholder="">
    </vz-tags-select>
</ng-template>
