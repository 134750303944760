import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

import { Task, string2Color } from '@models';

@Component({
    selector: 'vz-task-id-badge, [vz-task-id-badge]',
    template: `
        <span class="vz-task-id-badge layout center">
            @if (task(); as t) {
                <span class="vz-tib-main"
                    [style.background-color]="color()"
                    [title]="title()">
                    {{ t.shortId }}
                    @if (t.parentId) {
                        &nbsp;
                    }
                </span>
                @if (t.parentId) {
                    <i class="vz-tib-sub vzi-flow-cascade bg br50p"
                        [style.border-color]="color()"
                        [style.color]="color()">
                    </i>
                }
                @if (t.subTasks?.length) {
                    <span class="vz-tib-parent fs-xs txt-l bg no-wrap"
                        [style.color]="color()"
                        [style.border-color]="color()">
                        <span class="txt-l">{{ t.subTasks?.length }}</span>
                        <!-- <span class="txt-ok">{{ done }}</span>
                        <span class="txt-l"> / {{ total }}</span> -->
                    </span>
                }
            }
            @else {
                <div class="vz-loading-placeholder br4" style="width: 50px; height: 19px;"></div>
            }
        </span>
    `,
    styles: [`
        @import 'theme';
        :host {
            height: 19px;
            .vz-task-id-badge {
                height: 19px;
                & > .vz-tib-main {
                    color: @textColorInv;
                    border-radius: 4px;
                    padding: 1px 4px;
                    white-space: nowrap;
                    line-height: 1.2;
                    height: 19px;
                }
                & > .vz-tib-sub {
                    padding: 3px 2px;
                    border: 1px solid;
                    margin-left: -8px;
                    font-size: 12px;
                    position: relative;
                    top: 0;
                    height: 19px;
                }
                & > .vz-tib-parent {
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                    padding: 0 4px 0 3px;
                    border: 1px solid;
                    margin-left: -2px;
                    height: 19px;
                    line-height: 19px;
                }
            }
            &.vz-card-top {
                height: 20px;
                .vz-task-id-badge {
                    height: 20px;
                    & > span {
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                        height: 20px;
                        line-height: 19px;
                    }
                }
            }
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule]
})
export class TaskIdBadgeComponent {
    task = input<Task | undefined>();

    color = computed<string>(() => {
        const task = this.task();
        return task ? task._color || string2Color(task.projectId) : '#444';
    });

    // done: number = 0;
    // total: number = 0;

    title = computed<string>(() => {
        const task = this.task();
        if (task?.parentId) {
            return 'Подзадача';
        }
        else if (task?.subTasks?.length) {
            // this.total = this.task.subTasks.length;
            // this.done = this.task.subTasks.filter(t => t.archived || t.status == TaskStatus.Done).length;
            return `Есть подзадачи`; // TODO, выполнено: ${this.done} из ${this.total}`;
        }
        return '';
    });

}
